import React from "react";

import "./styles.scss";
import sportFedration from "../../asserts/photoGallery/new1.jpeg";
import sportAcademy from "../../asserts/photoGallery/grandSchmes.png";
import hotelInfra from "../../asserts/photoGallery/hostelandInfra.png";
import grantsSch from "../../asserts/photoGallery/grandSchmes.png";
import bookingManagement from "../../asserts/photoGallery/bookingManagement.jpeg";
import search from "../../asserts/photoGallery/search.png";
import LatestSlider from "../../components/LatestSlider/Index";
import Header from "../../components/Header";
import PhotoSlider from "../../components/PhotoSlider";
import s2 from "../../asserts/photoGallery/new2.jpeg";
import s3 from "../../asserts/photoGallery/new3.jpeg";
import s4 from "../../asserts/photoGallery/new4.jpeg";
import s5 from "../../asserts/photoGallery/new5.jpeg";
import s6 from "../../asserts/photoGallery/new6.jpeg";
import s7 from "../../asserts/photoGallery/new7.jpeg";
import s8 from "../../asserts/photoGallery/new8.jpeg";
import s9 from "../../asserts/photoGallery/new9.jpeg";
import s10 from "../../asserts/photoGallery/new10.jpeg";
import s11 from "../../asserts/photoGallery/new11.jpeg";
import s12 from "../../asserts/photoGallery/new12.jpeg";
import s13 from "../../asserts/photoGallery/new13.jpeg";

import s14 from "../../asserts/photoGallery/new14.jpeg";
import s15 from "../../asserts/photoGallery/new15.jpeg";
import s16 from "../../asserts/photoGallery/new16.jpeg";
import s17 from "../../asserts/photoGallery/new17.jpeg";
import s18 from "../../asserts/photoGallery/new18.jpeg";
import s19 from "../../asserts/photoGallery/new19.jpeg";
import s20 from "../../asserts/photoGallery/new20.jpeg";
import s21 from "../../asserts/photoGallery/new21.jpeg";
import s22 from "../../asserts/photoGallery/new22.jpeg";
import s23 from "../../asserts/photoGallery/new23.jpeg";
import Slider from "react-slick";
import { Col, Container, Row } from "react-bootstrap";
import img1 from "../../asserts/photoGallery/img1.png";
import img2 from "../../asserts/photoGallery/img2.png";
import img3 from "../../asserts/photoGallery/img3.png";
import ComingSoon from "../../components/ComingSoonPage/ComingSoon";

// const PhotoGallery = () => {
//   return (
//     <div className="head-container-main">
//       <ComingSoon />
//     </div>
//   );
// };

const PhotoGallery = () => {
  const items = [
    {
      id: 1,
      content: "Item 1",
      bgimg: sportFedration,
      hoverName: "Traning program Management",
    },
    {
      id: 2,
      content: "Item 2",
      bgimg: s2,
      hoverName: "Sports federation ",
    },
    {
      id: 3,
      content: "Item 3",
      bgimg: s3,
      hoverName: "sports Academy",
    },
    {
      id: 4,
      content: "Item 4",
      bgimg: s4,
      hoverName: "Hostel and Infrastructure",
    },
    {
      id: 5,
      content: "Item 5",
      bgimg: s5,
      hoverName: " Grants , Schemes and Scholarship ",
    },
    {
      id: 6,
      content: "Item 6",
      bgimg: s6,
      hoverName: "Booking Management",
    },
    {
      id: 7,
      content: "Item 7",
      bgimg: s7,
      hoverName: "Tournament Management ",
    },
    {
      id: 8,
      content: "Item 7",
      bgimg: s8,
      hoverName: "Tournament Management ",
    },
    {
      id: 9,
      content: "Item 7",
      bgimg: s10,
      hoverName: "Tournament Management ",
    },
    {
      id: 10,
      content: "Item 7",
      bgimg: s11,
      hoverName: "Tournament Management ",
    },
    {
      id: 11,
      content: "Item 7",
      bgimg: s12,
      hoverName: "Tournament Management ",
    },
    {
      id: 12,
      content: "Item 7",
      bgimg: s13,
      hoverName: "Tournament Management ",
    },
    {
      id: 13,
      content: "Item 7",
      bgimg: s14,
      hoverName: "Tournament Management ",
    },
    {
      id: 14,
      content: "Item 7",
      bgimg: s15,
      hoverName: "Tournament Management ",
    },
    {
      id: 15,
      content: "Item 7",
      bgimg: s16,
      hoverName: "Tournament Management ",
    },
    {
      id: 16,
      content: "Item 7",
      bgimg: s17,
      hoverName: "Tournament Management ",
    },
    {
      id: 17,
      content: "Item 7",
      bgimg: s18,
      hoverName: "Tournament Management ",
    },
    {
      id: 18,
      content: "Item 7",
      bgimg: s19,
      hoverName: "Tournament Management ",
    },
  
    {
      id: 19,
      content: "Item 7",
      bgimg: s20,
      hoverName: "Tournament Management ",
    },

    {
      id: 20,
      content: "Item 7",
      bgimg: s21,
      hoverName: "Tournament Management ",
    },
    
    {
      id: 21,
      content: "Item 7",
      bgimg: s23,
      hoverName: "Tournament Management ",
    },
   



   
  ];

  const gallerydata = [
    {
      id: 1,
      img: img1,
      heading: "Academy Management System",
    },
    {
      id: 2,
      img: img2,
      heading: "Academy Management System",
    },
    {
      id: 3,
      img: img3,
      heading: "Academy Management System",
    },
    {
      id: 4,
      img: img1,
    },
    {
      id: 5,
      img: img2,
    },
    {
      id: 6,
      img: img3,
    },
  ];

  var settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: false,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          autoplay: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="Photo-gallery-container ">
     
      <div className="bgcontainer center_title py-3 ">
        <div className="photo_gallery_container py-3 ">
    
          <div className="mt-3">
            <h3 className="photoGallery-heading mt-3">PHOTO GALLERY</h3>
          </div>
          <div className="photoGallery-search-field">
            <div>
              <input placeholder="Search" className="input" />
            </div>
            <div>
              <img width="100" src={search} />
            </div>
          </div>
          {/* <div className="photo-slider-container border border-danger">
          <PhotoSlider />
        </div> */}
          {/* <Container className="mb-4 py-4">
            <Row className="slider-container">
              <Slider {...settings}>
                {gallerydata?.map((item) => {
                  return (
                    <>
                      <Col
                        lg={6}
                        className=" w-100 mt-5 center_title gallery_sliding_container "
                        style={{
                          backgroundImage: `url(${item?.img})`,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          height: "52vh",
                        }}
                      >
                        <div className="py-5 gallery_text">
                          <h2 className="gallery_sliding_heading">
                            Academy Management System
                          </h2>
                          <div className="center_title">
                            <button className="discover_btn">Discover</button>
                          </div>
                        </div>
                      </Col>
                    </>
                  );
                })}
              </Slider>
             
            </Row>
          </Container> */}

        
        </div>

        {/* <div className="slider-container-photoGallery">
            <LatestSlider />
          </div> */}

        {/* <div  className="latest-photos-heading">
          <h4>Latest Photos</h4>
        </div> */}
      </div>
      <div className="photoGallery-gridPage">
        <div className="HeadingSection">
          {" "}
          <h2 className="gallery_section"> Section</h2>{" "}
        </div>

        <div className="container-element container-element-1">
          {items.map((item, index) => (
            <div
              key={item.id}
              className="grid-item"
              style={{ backgroundImage: `url(${item.bgimg})` }}
            >
              {/* <div className="overlay"> {item.hoverName} </div> */}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PhotoGallery;
