import "./styles.scss";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { VerticalStepperContext } from "../../../../Context/VerticalStepperCount/VerticalStepper";
import Swal from "sweetalert2";
import LeftRegistration from "../../../../components/LeftRegistration";
import RegisterAs from "../../../../components/VerifyDetails/RegisterAs";
import ATOM from "../../AtomComponent/ATOM/index";
import PersonalRegisterDetails from "../../PersonalDetailsComponent/PersonalRegisterDetails/index";
import AadharVerification from "../../RegisterWithAadhar/index";
import VerifyContact from "../../VerifyContactDetails/index";
import BankDetails from "../../BankComponent/BankDetails";
import BasicDetailsSupportStaff from "../../BasicComponent/BasicDetailsSupportStaff/index";
import ViewFormDetails from "../../ViewFormDetails";
import { Col, Container, Row } from "react-bootstrap";
import { FiChevronRight } from "react-icons/fi";
import { FiChevronLeft } from "react-icons/fi";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { get_Registration_Listing } from "../../../../config/registrationConfigUrl";
import { updateDetailsAtEveryStep } from "../../../../config/registrationConfigUrl";
import { isConstructorDeclaration } from "typescript";

function RegisterAsPage() {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user")) || {};
  // const BankDetails = JSON.parse(localStorage.getItem('bankDetails')) || {}

  let [viewFormPage, setViewFormPage] = useState(false);

  const {
    currentStep,
    setCurrentStep,
    setUserData,
    userData,
    userDocuments,
    setUploadedDocuments,
    handleNext,
    handleBack,
    db_step_regestration,
    isPasswordSet,
  } = useContext(VerticalStepperContext);
  const [isDisabled, setIsDisabled] = useState(true);

  const [show, setShow] = useState(false);
  const [showSubmit, seShowSubmit] = useState(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // console.log(BankDetails, "banksave==>")

  const handleSave = async () => {
    try {
      let response = await updateDetailsAtEveryStep({
        id: user?.id,
        account_holder_name: userData.account_holder_name,
        bank_account_number: userData.bank_account_number,
        bank_name: userData.bank_name,
        branch_name: userData.branch_name,
        ifsc_code: userData.ifsc_code,
        steps: 6,
      });
      if (response?.data?.status === true) {
        // console.log(response, "---")
        seShowSubmit(false);
        Swal.fire({
          title: "Success!",
          text: `${response?.data?.message}`,
          icon: "success",
        });
      } else {
        if (response?.data?.message) {
          // console.log("error<<>>", response?.data?.error.message);
          Swal.fire("Oops", `${response?.data?.error.message[0]}`, "error");
        } else {
          // console.log("error", response?.data?.error.message);
          Swal.fire("Oops", `${response?.data?.error.message[0]}`, "error");
        }
      }
    } catch (error) {
      // console.log("error<<>>", error?.data?.error?.message);
      Swal.fire("Oops", `${error?.response?.data?.message}`, "error");
    }
  };

  // const getRegistrationList = async () => {
  //   try {
  //     let response = await get_Registration_Listing(user?.id);
  //     if (response?.data?.status !== true) {
  //       if (response?.data?.message) {
  //         return;
  //       }

  //       console.log("error", response.data.message);
  //       return;
  //     }

  //     setUserData({ ...userData, ...response?.data?.data });
  //     if (response?.data?.data?.registration_docs) {
  //       setUploadedDocuments(response?.data?.data.registration_docs);
  //     }

  //     return;
  //   } catch (error) {
  //     console.log(error, "error");
  //     Swal.fire("Oops", `${error?.response?.data?.message}`, "error");
  //   }
  // };

  // useEffect(() => {
  //   getRegistrationList();
  // }, []);

  useEffect(() => {
    console.log(userData, "userDatafromUseeffect==>");
    const checkIfButtonShouldBeDisabled = () => {
      switch (currentStep.level) {
        case 3:
          return !userData?.orgnization_id;
        case 4:
          return (
            !userData?.sub_category_type ||
            !userData?.country ||
            !userData?.sport_id
          );
        case 5:
          return (
            !userData?.first_name ||
            !userData?.gender ||
            !userData?.address ||
            !userDocuments?.profile_image ||
            !userData?.maritial_status ||
            // (userData?.status == "ongoing" &&
            //   (!userData?.password ||
            //     userData?.password !== userData?.confirmPassword)) ||
            // (userData?.status == "ongoing" && !userData?.password) ||
            (userData?.status == "ongoing" &&
              !(
                userData?.password == userData?.confirmPassword ||
                Number(db_step_regestration) >= 5
              )) ||
            (userData?.status == "ongoing" &&
              !(userData.password || Number(db_step_regestration) >= 5)) ||
            (userData?.status == "ongoing" &&
              !isPasswordSet &&
              !(userData?.password == userData?.confirmPassword)) ||
            (userData?.status == "ongoing" &&
              !isPasswordSet &&
              !userData?.password) ||
            !(
              userDocuments.other ||
              userDocuments.tenth ||
              userDocuments.twelth ||
              userDocuments.ug ||
              userDocuments.pg ||
              userDocuments.phd
            )
          );
        case 6: {
          let disabledCondition =
            !userData?.bank_name ||
            !userData?.account_holder_name ||
            !userData?.bank_account_number ||
            !userData?.ifsc_code ||
            !userData?.branch_name ||
            !userDocuments?.cancelled_passbook_cheque_upload;

          //   console.log( "#####went inside level 6th");
          //  console.log('#####DisabledCondition', disabledCondition);
          return disabledCondition;
        }
        default:
          console.log("default case, enabling button");
          return false; // Button is enabled by default
      }
    };

    const disabled = checkIfButtonShouldBeDisabled();
    setIsDisabled(disabled);
  }, [
    currentStep,
    userData,
    userDocuments,
    db_step_regestration,
    isPasswordSet,
  ]);

  const onSubmit = async () => {
    setShow(true);
  };

  const handleSubmit = async () => {
    await handleNext();
    localStorage.setItem("status", true);
    navigate("/final-submit_individual_registration", { replace: true });
  };

  const buttonText = currentStep.level !== 6 ? "Continue" : "Submit";
  const buttonOnClick = currentStep.level !== 6 ? handleNext : onSubmit;

  return (
    <Container fluid>
      {show && (
        <>
          {/* <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Alert</Modal.Title>
            </Modal.Header>
            <Modal.Body>Do you really want to submit the Details ?</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" onClick={handleSubmit}>
                Yes
              </Button>
            </Modal.Footer>
          </Modal> */}

          <Modal show={show}>
            <Modal.Body className="modalBox">
              <div className="modalText">Do you really want to submit the Details ?</div>
              <div className="buttonDiv">
                <Button className="noBtn" onClick={handleClose}>
                  No
                </Button>
                <Button className="yesBtn" onClick={handleSubmit}>
                  Yes
                </Button>
              </div>
            </Modal.Body>
          </Modal>
        </>
      )}
      <Row>
        <Col lg={3} className="registration-container">
          <LeftRegistration />
        </Col>

        <Col lg={9} className="register-as-page-right-container">
          <div>
            {currentStep.level === 2 && <RegisterAs />}
            {currentStep.level === 3 && <ATOM />}
            {currentStep.level === 4 && <BasicDetailsSupportStaff />}
            {currentStep.level === 5 && <PersonalRegisterDetails />}
            {currentStep.level === 6 && !viewFormPage && <BankDetails />}
            {currentStep.level === 6 && viewFormPage && <ViewFormDetails />}
          </div>
          <div className="backAndnext-Btn">
            <div className="back-btn">
              {currentStep.level !== 0 && currentStep.level !== 2 && (
                <button
                  className="previous_btn mb-3"
                  onClick={
                    viewFormPage ? () => setViewFormPage(false) : handleBack
                  }
                  disabled={
                    userData.status === "ask_for_resubmit" &&
                    currentStep.level === 3
                  }
                >
                  <span className="marginL">
                    <FiChevronLeft />
                  </span>
                  Previous{" "}
                </button>
              )}

              {currentStep.level == 6 && !viewFormPage && (
                <button
                  className="previous_btn viewForm-btn"
                  onClick={() => {
                    setViewFormPage(true);
                  }}
                  disabled={showSubmit}
                >
                  {/* <span>
                    <FiChevronLeft /> 
                  </span> */}
                  View Form
                </button>
              )}
            </div>
            <div className="next-btn">
              {currentStep.level == 6 && (
                <button
                  className="Continue_btn save-btn mb-3"
                  onClick={handleSave}
                  disabled={isDisabled}
                >
                  Save
                  {/* <span>
                    <FiChevronRight />
                  </span> */}
                </button>
              )}
              <>
                {currentStep.level !== 6 ? (
                  <button
                    className="Continue_btn"
                    onClick={handleNext}
                    disabled={isDisabled}
                  >
                    Continue
                    <span>
                      <FiChevronRight />
                    </span>
                  </button>
                ) : (
                  <button
                    className="Continue_btn"
                    onClick={onSubmit}
                    disabled={showSubmit}
                  >
                    Submit
                    {/* <span>
                      <FiChevronRight />
                    </span> */}
                  </button>
                )}
              </>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default RegisterAsPage;
