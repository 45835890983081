import React, { useRef, useEffect, useState } from 'react';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import "./styles.scss";
import Header from '../../components/Header';
import mapPoint from "../../asserts/Infrastructure/mapPoint.svg";
import CloseIcon from '@mui/icons-material/Close';
import { coordinates } from "../../common/infra"
mapboxgl.accessToken = 'pk.eyJ1Ijoic2hyYWRoYTQ1IiwiYSI6ImNsdHdoNG1jMzEyNzUycG91dmI4ZHY4NmsifQ.Jbh3KA4JXEPHExw7oqAcyw';


const Index = () => {

  const [currIndex, setCurrIndex] = useState(null);
  const [mapboxObj, setMapBoxObj] = useState(null)
  const [stateCordinates, setStateCordinates] = useState({
    latitude: 23.3771,
    longitude: 85.360,
    zoom: 13
  })
  const [isZoom, setIsZoom] = useState(false)
  const [regionDetails, setRegionDetails] = useState({
    id: "",
    title: '',
    address: '',
    details: '',
    managerDet: '',
    latitude: '',
    longitude: '',
    image: '',
    location: '',
    sportFacilities: '',
  })
  const [isSwitchRegionOpen, setIsSwitchRegionOpen] = useState(false)
  const [regionPopup, setRegionPopup] = useState(false)


  // center: [85.360, 23.3641],
  useEffect(() => {

    const start = {
      center: [stateCordinates.longitude, stateCordinates.latitude],
      zoom: stateCordinates.zoom,
      pitch: 55,
      bearing: 0,
      // scrollZoom: false
    };

    const end = {
      center: [stateCordinates.longitude, stateCordinates.latitude],
      zoom: 12.5,
      pitch: 75,
      bearing: 130,
    };

    // const end = {
    //   center: [8.11862, 46.58842],
    //   zoom: 12.5,
    //   bearing: 130,
    //   pitch: 75
    // };


    const map = new mapboxgl.Map({
      container: 'map-container',
      style: 'mapbox://styles/shradha45/cltwo6dz501bq01nr7wit1cz1',
      ...start
    });

    map.on('style.load', () => {
      map.setFog({
        'color': 'rgb(220, 159, 159)',
        'high-color': 'rgb(36, 92, 223)',
        'horizon-blend': 0.4
      });

      map.addSource('mapbox-dem', {
        'type': 'raster-dem',
        'url': 'mapbox://mapbox.terrain-rgb'
      });

      map.setTerrain({
        'source': 'mapbox-dem',
        'exaggeration': 1.5
      });
    });

    let isAtStart = true;


    const target = isAtStart ? end : start;
    isAtStart = !isAtStart;

    console.log(map, "----------------")


    // map.flyTo({
    //   ...target,
    //   duration: 8000,
    //   essential: true
    // });


    setMapBoxObj(map)



    coordinates.forEach(coord => {
      // Create a marker container
      const markerContainer = document.createElement('div');
      markerContainer.className = 'marker-container';

      // Create a marker element
      const el = document.createElement('div');
      el.className = isZoom ? 'custom-marker-zoomed' : 'custom-marker';

      // Add text indicating the area
      const areaText = document.createElement('div');
      areaText.className = 'area-text';
      areaText.textContent = coord.title;
      markerContainer?.appendChild(el);
      markerContainer?.appendChild(areaText);

      // Create marker instance
      const marker = new mapboxgl.Marker(markerContainer)
        .setLngLat([coord.lng, coord.lat])
        .setPopup(new mapboxgl.Popup({ offset: 25 })

        )
        .addTo(map);

      // Add click event listener to the marker
      marker.getElement().addEventListener('click', () => {
        if (!isZoom) {
          setCurrIndex(coord.id);
          console.log(isZoom, "------")
          console.log("Marker clicked: " + coord.title);
        }
      });
    });


    // setIsZoom(false)
    // Cleanup
    return () => map.remove();
  }, []);


  useEffect(() => {
    if (mapboxObj) {
      // Your marker creation code
      coordinates.forEach(coord => {
        // Create a marker container
        const markerContainer = document.createElement('div');
        markerContainer.className = 'marker-container';

        // Create a marker element
        const el = document.createElement('div');
        el.className = isZoom ? 'custom-marker-zoomed' : 'custom-marker';

        // Add text indicating the area
        const areaText = document.createElement('div');
        areaText.className = 'area-text';
        areaText.textContent = coord.title;
        markerContainer.appendChild(el);
        markerContainer.appendChild(areaText);

        // Create marker instance
        const marker = new mapboxgl.Marker(markerContainer)
          .setLngLat([coord.lng, coord.lat])
          .setPopup(new mapboxgl.Popup({ offset: 25 }))
          .addTo(mapboxObj);

        // Add click event listener to the marker
        marker.getElement().addEventListener('click', () => {
          // console.log("Marker clicked: " + coord.title);
          if (!isZoom) {
            setCurrIndex(coord.id);
            console.log("Marker clicked: " + coord.title);
          } else {


            const targetCoordinates = {
              longitude: coord.lng + 0.0001,
              latitude: coord.lat + 0.001,
              zoom: 18,
              pitch: 55,
              bearing: 130,
            }

            console.log(targetCoordinates, "----------")


            mapboxObj.flyTo({
              center: [targetCoordinates.longitude, targetCoordinates.latitude],
              zoom: targetCoordinates.zoom,
              pitch: targetCoordinates.pitch,
              bearing: targetCoordinates.bearing,
              duration: 5000, // Adjust the duration as needed
              essential: true
            });

            setRegionPopup(true)

          }
          setRegionDetails(prevState => ({
            ...prevState,
            id: coord.id,
            title: coord.title,
            details: coord.details,
            managerDet:coord.managerDet,
            address: coord.address,
            latitude: coord.lat,
            longitude: coord.lng,
            image: coord.image,
            location: coord.location,
            sportFacilities: coord.sportFacilities
          }));
        });
      });
    }
  }, [mapboxObj, isZoom, isSwitchRegionOpen]);


  // console.log(regionPopup, "regionpop up")

  // console.log(mapboxObj, "=================")
  const handleCloseBtn = () => {
    setCurrIndex(null)
  }

  const handleExploreButton = (lat, long) => {

    // console.log(regionDetails, "title")


    const targetCoordinates = {
      longitude: long,
      latitude: lat,
      zoom: 15,
      pitch: 0,
      bearing: 0,
    }


    mapboxObj.flyTo({
      center: [targetCoordinates.longitude, targetCoordinates.latitude],
      zoom: targetCoordinates.zoom,
      pitch: targetCoordinates.pitch,
      bearing: targetCoordinates.bearing,
      duration: 5000, // Adjust the duration as needed
      essential: true
    });

    // Close the popup
    setCurrIndex(null);
    setIsZoom(true)
  }


  const handleSwitchRegion = () => {
    setIsSwitchRegionOpen(true)
    setIsZoom(true)
  }

  const handleCloseRegions = () => {
    setIsSwitchRegionOpen(false)
  }

  const handleRegionClick = (latitude, longitude, title) => {

    const targetCoordinates = {
      longitude: longitude,
      latitude: latitude,
      zoom: 18,
      pitch: 55,
      bearing: 130,
    }


    mapboxObj.flyTo({
      center: [targetCoordinates.longitude, targetCoordinates.latitude],
      zoom: targetCoordinates.zoom,
      pitch: targetCoordinates.pitch,
      bearing: targetCoordinates.bearing,
      duration: 5000, // Adjust the duration as needed
      essential: true
    });

    setIsSwitchRegionOpen(false)
    setRegionDetails(prevState => ({
      ...prevState,
      title: title
    }));
  };

  const closeRegionpopup = () => {
    setRegionPopup(false)
  }  
  
  const handleGetDirection = async (id) => {
    console.log(id, "id---")

    const location = coordinates.find(coord => coord.id === id);

    if (location) {
      window.open(location.link, '_blank');
    } else {
      // Handle case where ID is not found
      console.error('Location not found for the given ID:', id);
    }
  
  };



  // console.log(regionDetails, "region details")
  return (

    <div>

      {currIndex !== null && <div className='popop-container' >
        <div class='popup-container-right-top'>
          <div class='swiper-slide !h-auto swiper-slide-active pop-up-container' style={{ textAlign: "start" }}>
            <div class='transition-opacity duration-600'>
              <div class='overflow-hidden bg-palm-gradient text-ocean rounded-md tablet-wide:rounded-lg'>
                <div class='relative z-1 w-full aspect-video -mb-6 text-white img-popup'>
                  <img src={coordinates[currIndex]?.image} width="400px" height="250px" alt="logo" />
                </div>
                <div className='clear-btn'>
                  <CloseIcon className="closeIcon" onClick={handleCloseBtn} />
                </div>
                <div class='p-8 pt-12 bg-palm-gradient text-ocean map_card_height_title'>
                  <h3 class='typeface-h3 mb-2'>{coordinates[currIndex]?.title}</h3>
                  <p class='typeface-body'>{coordinates[currIndex]?.address}</p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <button className='explore-btn' onClick={() => handleExploreButton(coordinates[currIndex].lat, coordinates[currIndex].lng)}>EXPLORE</button>
          </div>
        </div>

      </div>}
      <div id="map-container" className="map-container">
      </div>



      {isZoom && (
        <div className='left-bottom-btns'>
          <div className='switchRegion' onClick={handleSwitchRegion}>SWITCH REGIONS</div>
          <div className='location-name'>{regionDetails?.title}</div>
        </div>
      )}


      {isSwitchRegionOpen && (
        <div className='switchregion-popup'>
          <div className='selection-region-heading'>
            <div>
              <p>Select a Region</p>
            </div>
            <div className='region-close' onClick={handleCloseRegions}>
              <CloseIcon />
            </div>
          </div>
          <div className='switch-region-divs'>
            {coordinates.map((coord, index) => (
              <div key={index} className='coordinate-image' onClick={() => handleRegionClick(coord.lat, coord.lng, coord.title)}>
                <img src={coord.image} alt={`Coordinate ${index}`} width="200px" height="100px" />
                <p>{coord.title}</p>
              </div>
            ))}
          </div>
        </div>
      )}


      {regionPopup && (
        <div className='regional-details-popup'>
          <div className='closebutton-regional-details'>
            <button onClick={closeRegionpopup}>Close X</button></div>
          <div className='heading-regional-details'>{regionDetails.title}</div>
          <div className='m-0 p-0 mapPoint-flex'>
            <div>
              <img src={mapPoint} />
            </div>
            <div>
              <p className='my-1'>{regionDetails.location}</p>
            </div>
          </div>
          <div>
            <img src={regionDetails.image} width='600' height='300' />
          </div>
          <div className='green-layout'>
            
            <p className='m-0 p-0'>Sport Facilities: {regionDetails.sportFacilities}</p>
          </div>
          <div>
            <p>{regionDetails.details}</p>
            <p style={{paddingTop:"15px"}}><b>Stadium Manager</b></p>
            <p><b>Contact No. </b>{regionDetails.managerDet}</p>
          </div>
          <div>
            <button
              className="get-direction-btn"
              onClick={() => handleGetDirection(regionDetails.id)}
            >
              Get Direction
            </button>
          </div>

        </div>
      )}

    </div>
  );
}

export default Index;

