import React, { useState, useRef } from "react";
import "./styles.scss";
import Header from "../../components/Header";
import Slider from "../../components/LatestSlider/Index"
import video1 from "../../asserts/LatestVideos/Group 487.png";
import playBtn from "../../asserts/LatestVideos/play-btn.png";
import v1 from "../../asserts/LatestVideos/v1.png";
import v2 from "../../asserts/LatestVideos/v2.png";
import v3 from "../../asserts/LatestVideos/v3.png";
import v4 from "../../asserts/LatestVideos/v4.png";
import v5 from "../../asserts/LatestVideos/v5.png";
import v6 from "../../asserts/LatestVideos/v6.png";
import ComingSoon from "../../components/ComingSoonPage/ComingSoon";

const LatestVideos = () => {
  return (
    <div className="head-container-main">
      <ComingSoon />
    </div>
  );
};

// function LatestVideos() {
//   // const videoRef = useRef(null);
//   // const [isPlaying, setIsPlaying] = useState(false);

//   // const togglePlay = () => {
//   //     if (isPlaying) {
//   //       videoRef.current.pause();
//   //     } else {
//   //       videoRef.current.play();
//   //     }
//   //     setIsPlaying(!isPlaying);
//   //   };

//   const openingCeremony = [
//     {
//       img: v1,
//       date: "MON 19 JUNE 2023",
//       desc: "20th Uttarakhand State Senior Badminton Championship 2022",
//       link: "https://youtu.be/UgS-_E70P64?si=MV4gJ6E8d6ALwROd",
//     },
//     {
//       img: v2,
//       date: "MON 19 JUNE 2023",
//       desc: "1st Uttarakhand Engineers Federation Cricket Cup-2023||peyjal Vs Irrigation",
//       link: "https://youtu.be/UgS-_E70P64?si=MV4gJ6E8d6ALwROd",
//     },
//     {
//       img: v3,
//       date: "MON 19 JUNE 2023",
//       desc: "Uttarakhand State Championship || Football || Day 2",
//       link: "https://youtu.be/UgS-_E70P64?si=MV4gJ6E8d6ALwROd",
//     },
//     {
//       img: v4,
//       date: "MON 19 JUNE 2023",
//       desc: "Uttarakhand State Championship || Football || Day 2",
//       link: "https://youtu.be/UgS-_E70P64?si=MV4gJ6E8d6ALwROd",
//     },
//     {
//       img: v5,
//       date: "MON 19 JUNE 2023",
//       desc: "Uttarakhand State Championship || Football || Day 2",
//       link: "https://youtu.be/UgS-_E70P64?si=MV4gJ6E8d6ALwROd",
//     },
//     {
//       img: v6,
//       date: "MON 19 JUNE 2023",
//       desc: "Uttarakhand State Championship || Football || Day 2",
//       link: "https://youtu.be/UgS-_E70P64?si=MV4gJ6E8d6ALwROd",
//     },
//   ];

//   const handleVideoClick = () => {
//     window.open("https://youtu.be/6C24WErIJ0w?si=XUU2itG2sYQ1f77i", "_blank");
//   };

//   const playVideos = (link) => {
//     window.open(link, "_blank");
//   };

//   return (
//     <div>
//       <div className="latest-videos-container">
//         <div className="text-center ">
//           <p className="text-white common-heading">LATEST VIDEOS</p>
//         </div>
//         <div className="slider-cont">
//           <Slider />
//         </div>
//       </div>
//       <div className="videos-container">
//         <div className="latest-videos-cont1">
//           <div className="latest-vd-left-cont">
//             <button>CEREMONY</button>
//             <p>MON 19 JUNE 2023</p>
//             <h3>
//               Premier Division Jharkhand <br />
//               Sporting Club || Practice <br /> Session 🔥 All Stars
//             </h3>
//           </div>
//           <div className="video1-top">
//             {/* <img src={playBtn} onClick={togglePlay}  width='60' />
//             <video
//              ref={videoRef} 
//               loop
//               muted
//               className="video1-styling"
//               style={{
//                 maxWidth: "100%",
//                 height: "500px",
//                 borderRadius: "36px",
//                 objectFit: "cover",
//               }}
              
//             >
//               <source src="/assets/video/video1.mp4" type="video/mp4" />
//             </video> */}
//             <img src={video1} onClick={handleVideoClick} width="800" />
//           </div>
//         </div>
//       </div>
//       <p className="openingCeremony-heading" id="latest-videos-id">Opening Ceremony</p>
//       <div className="opening-ceremony-container">
//         {openingCeremony.map((item, index) => (
//           <div className="opening-ceremony-mapContainer" key={index}>
//             <div className="img-cont">
//               <img src={item.img} className="img-map-cont" />
//               <img
//                 src={playBtn}
//                 className="playBtn-maps"
//                 width="25"
//                 onClick={() => playVideos(item.link)}
//               />
//             </div>
//             <div className="ceremony-line">
//               <button>CEREMONY</button>
//               <p>{item.date}</p>
//             </div>
//             <div>
//               <p className="lv-desc">{item.desc}</p>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// }

export default LatestVideos;
