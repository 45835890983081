import { useState, useEffect } from "react";
import logo from "../../asserts/Header/Jharkhand Sports Directorate logo_white 1.png";
import logo2 from "../../asserts/login/jharkhand logo 2.png";
import logo3 from "../../asserts/login/Jharkhand Sports Directorate logo 1.png";
import search from "../../asserts/Header/Search.png";
import searchBlack from "../../asserts/Header/search-black.png";
import "./styles.scss";
import MenuPopUp from "../MenuPopUp";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import IconChevronRight from "../ArrowRight";
import { useNavigate } from "react-router-dom";
import SearchBar from "../SearchBar/Index";
import { Col, Container, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";

export default function Header() {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [url, setUrl] = useState("");
  const [scrolled, setScrolled] = useState(false);
  const loginToken = localStorage.getItem("access_token");


  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const currentPageUrl = window.location.href;
    const contentAfterSlash = currentPageUrl.split("/").pop();
    setUrl(`${contentAfterSlash}`);
  }, []);

  const urlTextMap: { [key: string]: string } = {
    mission_vision: "Mission Vision",
    calender: "Calendar",
    athletes: "Athletes",
    infrastructure: "Infrastructure",
    organisational_structure: "Organisational Structure",
    aboutUs: "About Us",
    "e-tender": "E-Tender",
  };

  const location = useLocation();
  const textToRender = urlTextMap[url];

  return (
    <>

      <div className="original container-fluid">
        <div className="menuHeader container-fluid">
          <div className="content">
            <div
              className={`header_content ${scrolled ? "scrolled" : ""} ${isMenuOpen ? "menu-open" : ""
                }`}>
            </div>

            <div>
              <div className="row">

                <div className="col-lg-3 col-md-3" style={{ alignContent: "center" }}>
                  <button className="menu" onClick={() => setIsMenuOpen(true)}>
                    MENU{" "}
                    <MenuIcon
                      style={{ backgroundColor: "transparent", border: "none" }}
                    />
                  </button>
                </div>


                <div className="col-lg-4 col-md-4 d-flex justify-content-center">
                  {location.pathname === "/" || location.pathname === "/home" ? (
                    <>
                      <div className="logo_wrapper">
                        <div>
                          <img
                            src={logo2}
                            alt="logo"
                            className="logo"
                            onClick={() => navigate("/home")}
                          />
                        </div>
                        <div className="mx-3">
                          <img
                            src={logo3}
                            alt="logo"
                            className="logo"
                            onClick={() => navigate("/home")}
                          />
                        </div>
                        <div>
                          <img
                            src={logo}
                            alt="logo"
                            className="logo"
                            onClick={() => navigate("/home")}
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    <div>
                      <img
                        src={logo}
                        alt="logo"
                        className="logo"
                        onClick={() => navigate("/home")}
                      />
                    </div>
                  )}

                </div>


                <div className="col-lg-5 col-md-5 search_wrapper d-flex justify-content-end" >

                  <SearchBar />


                </div>


              </div>
            </div>

          </div>
        </div>
      </div>

      <div className="mobile container-fluid">
        <div className="menuHeader container-fluid">
          <div className="">
            <div
              className={`header_content ${scrolled ? "scrolled" : ""} ${isMenuOpen ? "menu-open" : ""
                }`}>
            </div>

            <div>
              <div className="row">

                <div className="col-6" style={{ alignContent: "center" }}>
                  <button className="mobilemenu" onClick={() => setIsMenuOpen(true)}>
                   
                    <MenuIcon
                      style={{ backgroundColor: "transparent", border: "none" }}
                    />
                  </button>
                </div>
                <div className="col-6 d-flex " >

                  <SearchBar />


                </div>
              </div>



              <div className="d-flex justify-content-center">
                {location.pathname === "/" || location.pathname === "/home" ? (
                  <>
                    <div className="logo_wrapper">
                      <div>
                        <img
                          src={logo2}
                          alt="logo"
                          className="logo"
                          onClick={() => navigate("/home")}
                        />
                      </div>
                      <div className="mx-3">
                        <img
                          src={logo3}
                          alt="logo"
                          className="logo"
                          onClick={() => navigate("/home")}
                        />
                      </div>
                      <div>
                        <img
                          src={logo}
                          alt="logo"
                          className="logo"
                          onClick={() => navigate("/home")}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <div>
                    <img
                      src={logo}
                      alt="logo"
                      className="logo"
                      onClick={() => navigate("/home")}
                    />
                  </div>
                )}

              </div>





            </div>

          </div>
        </div>
      </div>






      {/* <Container className="navHeader container-fluid">
        <Container
          fluid
          className={`header_fixed_container ${scrolled ? "scrolled" : ""} ${
            isMenuOpen ? "menu-open" : ""
          }`}
        >
          <Container className="">
            <Row className="py-4">
              <Col lg={4}>
                <button className="menu" onClick={() => setIsMenuOpen(true)}>
                  MENU{" "}
                  <MenuIcon
                    style={{ backgroundColor: "transparent", border: "none" }}
                  />
                </button>
              </Col>
              <Col lg={4} className="text-center">
                {location.pathname === "/" || location.pathname === "/home" ? (
                  <>
                    <div className="logo_wrapper">
                      <div>
                        <img
                          src={logo2}
                          alt="logo"
                          className="logo"
                          onClick={() => navigate("/home")}
                        />
                      </div>
                      <div className="mx-3">
                        <img
                          src={logo3}
                          alt="logo"
                          className="logo"
                          onClick={() => navigate("/home")}
                        />
                      </div>
                      <div>
                        <img
                          src={logo}
                          alt="logo"
                          className="logo"
                          onClick={() => navigate("/home")}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <div>
                    <img
                      src={logo}
                      alt="logo"
                      className="logo"
                      onClick={() => navigate("/home")}
                    />
                  </div>
                )}
              </Col>
              <Col lg={4} className="search_wrapper d-flex justify-content-end">
                <div className="">
                  <SearchBar />
                </div>
              </Col>
            </Row>
          </Container>
        </Container>
        </Container> */}

      {isMenuOpen && <MenuPopUp handleClose={() => setIsMenuOpen(false)} />}
    </>
  );
}
